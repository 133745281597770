function pcschildrenfn(pcsid, datas) {
  let Stationdatas = datas.filter((e) => {
    return e.pid == pcsid
  })
  Stationdatas.forEach((e) => {
    e.pcschildren = pcschildrenfn(e.id, datas)
  })
  return Stationdatas
}
export default function Stationtree(datas) {
  let dataone = datas.filter((e) => {
    return !datas.find((f) => {
      return f.id == e.pid
    })
  })
  dataone.forEach((e) => {
    e.pcschildren = pcschildrenfn(e.id, datas)
  })
  return dataone
}
