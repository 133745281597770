export default function pcschildren(
  datas = [],
  dqpid = '',
  id = 'id',
  pid = 'pid',
  children = 'children'
) {
  let r = []
  r = datas.filter((f) => {
    if (dqpid) {
      return f[pid] == dqpid
    } else {
      return !datas.find((fi) => {
        return fi[id] == f[pid]
      })
    }
  })
  r.forEach((e) => {
    e[children] = pcschildren(datas, e.id)
  })
  return r
}
