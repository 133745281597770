<template>
  <div class="zappmain-container">
    <!-- <transition mode="out-in" name="fade-transform"> -->
    <el-scrollbar style="height: 100%">
      <!-- <keep-alive :include="keepAliveNameList" :max="keepAliveMaxNum"> -->
      <!-- <component :is="Component" :key="routerKey" /> -->
      <router-view />
      <!-- </keep-alive> -->
    </el-scrollbar>
    <!-- </transition> -->
    <div
      v-if="USearch.uUser == 'zxwh'"
      style="position: fixed; right: 10px; bottom: 20px"
    >
      <el-button @click="goold">返回旧版</el-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Zappmain',
    components: {},
    data() {
      return {}
    },
    computed: {
      routeitemtitle() {
        let matched = this.$route.matched
        matched = matched[matched.length - 1]
        if (matched.components.default.name == 'Iframe') {
          return false
        } else {
          return this.$route.meta.title
        }
      },
      USearch() {
        return this.$store.state.user.USearch
      },
    },
    created() {},
    mounted() {},
    methods: {
      goold() {
        window.location.href = './app.html'
      },
    },
  }
</script>
<style lang="scss" scoped></style>
