import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { setupZ } from '@/z'
import { setupStore } from './store'

const app = createApp(App)
setupZ(app)
setupStore(app)
app.use(router).mount('#app')
