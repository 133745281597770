export default async function (jsons, datas) {
  let r = []
  for (let index = 0; index < jsons.length; index++) {
    const element = jsons[index]
    if (element.tablecallbackasync) {
      let inputscallbacks = await element.tablecallbackasync.call(this, datas)
      r = r.concat(inputscallbacks)
    } else if (element.tablecallback) {
      let inputscallbacks = element.tablecallback.call(this, datas)

      r = r.concat(inputscallbacks)
    } else {
      r.push(element)
    }
  }
  return r
}
