import request from '@/z/callback/request'

import requestbase from '@/z/callback/requestbase'
import store from '../index'

const state = {
  station: [],
  stations: new Set(),
  StationPids: {},
  StationPidsgtel: {},
  stationfalg: false,
}

const getters = {
  station: (state) => state.station,
}

const mutations = {
  setStation(state, station) {
    if (station) {
      state.station = station
    } else {
      state.station = []
    }
  },
  setStations(state, station = []) {
    state.stations = new Set([...state.stations, ...station])
  },
  StationPids(state, { pcs, station = [] }) {
    state.StationPids[pcs] = station
    state.stations = new Set([...state.stations, ...station])
  },
  StationPidsgtel(state, { pcs, station = [] }) {
    state.StationPidsgtel[pcs] = station
  },
  setstationfalg(state, data) {
    state.stationfalg = true
    state.station = data
  },
  delstation(state) {
    state.station = []
    state.stationfalg = false
    state.stations = new Set()
    state.StationPids = {}
    state.StationPidsgtel = {}
  },
}

const actions = {
  setStation({ commit }, station) {
    commit('setStation', station)
  },

  async getstationone({ state, commit }, pcs = store.state.user.token.pcs) {
    // id
    if (pcs == '') {
      return []
    }
    let station = false
    if (state.stationfalg) {
      station = state.station.find((e) => {
        return e.id == pcs
      })
    }
    if (!station) {
      station = [...state.stations].find((e) => {
        return e.id == pcs
      })
    }
    if (!station) {
      // request
      let stationreq = await request('/StationPid', {
        sid: `id in (${pcs})`,
      })
      commit('setStations', stationreq)
      if (stationreq.length == 1) {
        station = stationreq[0]
      }
    }
    return station ? [station] : []
  },
  async getstationgtelpid({ state, commit }, pcs) {
    if (!pcs) {
      return []
    }
    if (state.StationPidsgtel[pcs]) {
      return state.StationPidsgtel[pcs]
    }
    let Stations = []

    Stations = await request('/ChargeCarPcs', { pcs: pcs })

    commit('StationPidsgtel', { pcs, station: Stations })
    return Stations
  },
  async getstationpid({ state, commit }, pcs) {
    if (!pcs) {
      return []
    }
    if (state.StationPids[pcs]) {
      return state.StationPids[pcs]
    }
    let Stations = []
    if (state.stationfalg) {
      Stations = state.station.filter((e) => {
        return e.pid == pcs
      })
      return Stations
    }
    Stations = await request('/StationPid', { pid: pcs })

    commit('StationPids', { pcs, station: Stations })
    return Stations
  },
  async getstationpc({ dispatch }, pcsid = store.state.user.token.pcs) {
    // state, commit,
    let r = new Set()
    let pcs = await dispatch('getstationone', pcsid)
    if (pcs.length == 0) {
      return []
    }
    let upcs = await dispatch('getstationone', store.state.user.token.pcs)

    let pdatas = await dispatch('getpcspfn', pcsid)

    r = new Set([...upcs, ...pdatas])
    return [...r]
  },
  async getpcspfn({ dispatch }, pcsid, r = []) {
    let dqpcs = await dispatch('getstationone', pcsid)
    if (dqpcs.length == 0) {
      return r
    }
    dqpcs = dqpcs[0]
    let pcsdatas = await dispatch('getstationpid', dqpcs.id)

    r = new Set([...r, ...pcsdatas])

    if (dqpcs.id == store.state.user.token.pcs) {
      return [...r]
    } else {
      return await dispatch('getpcspfn', dqpcs.pid, [...r])
    }
  },
  async getstationpidsfn(
    { dispatch },
    { pcsid = store.state.user.token.pcs, datas = [] }
  ) {
    let dqpcs = await dispatch('getstationone', pcsid)
    if (dqpcs.length == 0) {
      return [...datas]
    }
    dqpcs = dqpcs[0]
    datas = new Set([...datas, dqpcs])
    if (dqpcs.pid == store.state.user.token.pcs) {
      return [...datas]
    } else if (dqpcs.id == store.state.user.token.pcs) {
      return [...datas]
    } else {
      return await dispatch('getstationpidsfn', {
        pcsid: dqpcs.pid,
        datas: [...datas],
      })
    }
  },
  async getstationpidsallfn({ dispatch }, array = []) {
    let data = []
    for (let index = 0; index < array.length; index++) {
      const element = array[index]
      data = data.concat(
        await dispatch('getstationpidsfn', { pcsid: element.id })
      )
    }
    data = new Set([...data])
    return [...data]
  },
  async getstationpidsall({ dispatch }, pcss) {
    let parsfn = [],
      r = new Set()
    pcss.forEach((e) => {
      parsfn.push(dispatch('getstationpidsfn', { pcsid: e.id }))
      console.log({ pcsid: e.id }, '111111111')
    })
    let datas = await Promise.all(parsfn)
    console.log(datas, '111111111')
    datas.forEach((e) => {
      r = new Set([...r, ...e])
    })
    let rdata = []
    r.forEach((e) => {
      if (
        !rdata.find((f) => {
          return f.id == e.id
        })
      ) {
        rdata.push(e)
      }
    })
    return [...rdata]
  },
  async getstation({ state, dispatch, commit }, data = []) {
    if (state.stationfalg) {
      return state.station
    }
    let Station = await request('/Station', {
      csta: data.length,
      cmax: 1000,
    })
    data = data.concat(Station)
    if (Station.length < 1000) {
      commit('setstationfalg', data)
      return data
    } else {
      return await dispatch('getstation', data)
    }
  },
  async Stationadd({ commit }, data) {
    let par = await requestbase('/Stationadd', data)
    commit('delstation')
    return par
  },
  async Stationedt({ commit }, data) {
    let par = await request('/Stationedt', data)
    commit('delstation')
    return par
  },
  async Stationdel({ commit }, id) {
    let par = await request('/Stationdel', { id: id })
    commit('delstation')
    return par
  },
  // async getUToPcsuUser(a,uUser) {
  //   let r = await request('/UToPcs', {uUser})
  //   re
  // },
}
// http://fdd.gpsserver.cn/
export default { state, getters, mutations, actions }
