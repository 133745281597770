<template>
  <div class="zmenu-container">
    <el-menu
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      :router="true"
    >
      <el-menu-item
        v-for="item in menudata"
        :key="item.path"
        :index="menuindex(item)"
      >
        <div
          style="
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
          "
        >
          <i class="iconfont" v-html="item.meta.icon"></i>
        </div>
        <template #title>
          {{ item.meta.title }}
        </template>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
  export default {
    name: 'Zmenu',
    data() {
      return {
        isCollapse: true,
      }
    },
    computed: {
      menudata() {
        return this.$store.getters['routes/menudata']
      },
    },
    mounted() {},
    methods: {
      menuindex(e) {
        return `${e.path}/${e.children ? e.children[0].path : ''}`
      },
    },
  }
</script>
<style lang="scss" scoped></style>
