import request from '@/z/callback/request'
import store from '@/store/index'
import requestbase from '@/z/callback/requestbase'
const state = {
  upw: {},
  upwtypes: ['0', '12', '18', '19', '20', '4', '3'],
  upwuUser: '',
}

const getters = {
  upw: (state) => state.upw,
  b_info: (state) => state.upw.b_info == 1,
  b_rz: (state) => state.upw['b_rz'] == 1,
  b_dyfrz: (state) => state.upw['b_dyfrz'] == 1,
  b_glf: (state) => state.upw['b_glf'] == 1,
  fyfalg: () => {
    let user = store.state.user.token
    return user.utype == 15 && user.udj == 1 ? false : true
  },
  b_fr: (state) => state.upw['b_fr'] == 1,
  b_bxr: (state) => state.upw['b_bxr'] == 1,
}

const mutations = {
  setUpw(state, upw = {}) {
    state.upw = upw
  },
  setupwuUser(state, uUser = {}) {
    state.upwuUser = uUser
  },
}

const actions = {
  setUpw({ commit }, upw) {
    commit('setUpw', upw)
  },
  async getUpw({ state, commit }) {
    let user = store.state.user.token
    if (state.upwuUser == user.uUser) {
      return state.upw
    }
    if (
      !state.upwtypes.find((e) => {
        return e == user.utype
      })
    ) {
      return {}
    }
    let UPW = await request('/UPW', { uUser: user.uUser })
    UPW = UPW.length == 0 ? {} : UPW[0]

    commit('setUpw', UPW)
    commit('setupwuUser', user.uUser)
    return UPW
  },
  async UPWedt(store, data) {
    let par = await requestbase('/UPWedt', data)

    return par
  },
}

export default { state, getters, mutations, actions }
