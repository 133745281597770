import request from '@/z/callback/request'
import imgsrc from '@/z/callback/imgsrc'
const state = {
  setting: '',
  UArea: {},
}

const getters = {
  setting: (state) => state.setting,
  appbj: (state) => {
    let appbjs = ['/appbj.jpg', '/appbj1.jpg', '/appbj2.jpg']
    let num = state.UArea.b_mode || 0
    return imgsrc(appbjs[num])
  },
}

const mutations = {
  setSetting(state, setting) {
    if (setting) {
      state.setting = setting
    } else {
      state.setting = []
    }
  },
  setUArea(state, UArea) {
    state.UArea = UArea
  },
}

const actions = {
  setSetting({ commit }, setting) {
    commit('setSetting', setting)
  },
  async getUArea({ state, commit }) {
    if (Object.keys(state.UArea).length != 0) {
      return state.UArea
    }
    let UArea = await request('/UArea')
    UArea = UArea.length == 1 ? UArea[0] : {}
    commit('setUArea', UArea)
    return UArea
  },
}

export default { state, getters, mutations, actions }
