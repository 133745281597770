import store from '../index'

const state = {
  falg: '',
  usearch: '',
  yzmfalg: false,
}

const getters = {
  falg: (state) => state.falg,
  UPWfalg: () => {
    return (
      store.state.user.token.utype == 4 ||
      (store.state.user.token.utype == 0 &&
        [0, 1].find((e) => e == store.state.user.token.utype))
    )
  },
  dlfalg: () => {
    if (
      store.state.user.token.utype == 7 &&
      store.state.user.token.utype.udj != 0
    ) {
      return false
    }
    if (store.state.user.token.utype == 15 && store.state.user.token.udj == 1) {
      return false
    }
    if (store.getters['upw/upw'].b_dl == 1) {
      return true
    }
    return false
  },
  jefalg: () => {
    if (store.state.user.token.utype == 15 && store.state.user.token.udj == 1) {
      return false
    }
    return true
  },
}

const mutations = {
  setFalg(state, falg) {
    if (falg) {
      state.falg = falg
    } else {
      state.falg = []
    }
  },
  setyzmfalg(state, falg) {
    state.yzmfalg = falg
  },
}

const actions = {
  setFalg({ commit }, falg) {
    commit('setFalg', falg)
  },
}

export default { state, getters, mutations, actions }
