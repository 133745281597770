const state = {
  cs: '',
}

const getters = {
  cs: (state) => state.cs,
}

const mutations = {
  setCs(state, cs) {
    if (cs) {
      state.cs = cs
    } else {
      state.cs = []
    }
  },
}

const actions = {
  setCs({ commit }, cs) {
    commit('setCs', cs)
  },
}

export default { state, getters, mutations, actions }
