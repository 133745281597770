import request from '@/z/callback/request'
import qs from 'qs'
import { addEdit, edtEdit } from '@/api/chargecaradd'
import { ElMessage } from 'element-plus'
// import { FLAG_NUMBER } from 'html2canvas/dist/types/css/syntax/tokenizer'
// import { gte } from 'lodash'
const state = {
  chargecar: [],
  ChargeCarSearch: new Map(),
  Camera: '',
  chargecarreq: [],
  chargecars: [],
  chargecarsreq: [],
}

const getters = {
  chargecar: (state) => state.chargecar,
}

const mutations = {
  setChargecar(state, chargecar) {
    state.chargecar = [...state.chargecar, ...chargecar]
    // if (chargecar) {
    //   state.chargecar = chargecar
    // } else {
    //   state.chargecar = []
    // }
  },
  setCamera(state, Camera) {
    state.Camera = Camera
  },

  setChargeCarSearch(state, { key, value }) {
    state.ChargeCarSearch.set(key, value)
  },
  delChargeCarSearch(state) {
    state.ChargeCarSearch.clear()
    state.chargecar = []
    state.chargecarreq = []
  },
  setchargecarreq(state, chargecar) {
    state.chargecarreq = [...state.chargecarreq, ...chargecar]
  },
  setchargecars(state, chargecar) {
    state.chargecars = [...state.chargecars, ...chargecar]
  },
  setchargecarsreq(state, chargecar) {
    state.chargecarsreq = [...state.chargecarsreq, ...chargecar]
  },
}

const actions = {
  async getchargecar({ state, commit, dispatch }, gtel) {
    if (!gtel) {
      return {}
    }
    let r = state.chargecars.find((e) => {
      return e.gtel == gtel
    })
    if (!r) {
      let reqr = state.chargecarsreq.find((e) => {
        return e == gtel
      })

      if (reqr) {
        return await new Promise((res) => {
          setTimeout(() => {
            res(dispatch('getchargecar', gtel))
          }, 100)
        })
      }
      commit('setchargecarsreq', [gtel])
      let chargecar = await request('/ChargeCar', { gtel: gtel })
      if (chargecar.length == 1) {
        commit('setchargecars', chargecar)
        r = chargecar[0]
      } else {
        commit('setchargecars', { gtel })
      }
    }
    return r ? r : {}
  },
  async getchargecarbask({ state, commit, dispatch }, gtel) {
    if (!gtel) {
      return 0
    }
    let r = state.chargecar.find((e) => {
      return e.gtel == gtel
    })
    // console.log(r, gtel)
    if (!r) {
      let reqr = state.chargecarreq.find((e) => {
        return e == gtel
      })

      if (reqr) {
        return await new Promise((res) => {
          setTimeout(() => {
            res(dispatch('getchargecarbask', gtel))
          }, 100)
        })
      }
      commit('setchargecarreq', [gtel])
      let chargecar = await request('/ChargeCar', { gtel: gtel })
      if (chargecar.length == 1) {
        commit('setChargecar', chargecar)
        r = chargecar[0]
      } else {
        chargecar = [{ gtel: gtel }]
        commit('setChargecar', chargecar)
        r = {}
      }
    }
    return r ? r.b_ask : '0'
  },
  async getCamera({ commit, state }) {
    if (typeof state.Camera == 'object') {
      return state.Camera
    }
    let data = await request('/Camera')
    commit('setCamera', data)
    return data
  },
  setChargecar({ commit }, chargecar) {
    commit('setChargecar', chargecar)
  },
  async getchargecarxx({ commit }, datas = []) {
    let r = []
    if (datas.length == 0 || datas.length >= 3000) {
      r = await request('/ChargeCar')
    } else {
      let promiseall = []
      datas.forEach((e) => {
        promiseall.push(request('/ChargeCar', { gtel: e.gtel }))
      })
      let rdatdas = await Promise.all(promiseall)
      rdatdas.forEach((e) => {
        r = r.concat(e)
      })
    }
    commit('setChargecar', r)
    if (datas.length == 0) {
      return r
    }
    r = r.filter((e) => {
      return datas.find((f) => {
        return f.gtel == e.gtel
      })
    })
    return r
  },
  async ChargeCarSearch({ state, commit }, data = {}) {
    let r = []
    let key = qs.stringify(data)

    if (state.ChargeCarSearch.has(key)) {
      r = state.ChargeCarSearch.get(key)
    } else {
      r = await request('/ChargeCarSearch', data)
      commit('setChargeCarSearch', { key: key, value: r })
    }
    return r
  },
  async chargecaradd({ commit }, data = {}) {
    return await addEdit(data).then((par) => {
      if (par.value == 'succeed') {
        commit('delChargeCarSearch')
      }
      return par
    })
  },
  async camera({ dispatch }, data = {}) {
    return await dispatch('chargecaredt', data)
  },
  async chargecaraddexcel({ commit }, data = {}) {
    let reqs = [],
      excels = data.excel
    delete data.excel
    // for (var i=0;i<)
    excels.forEach((e) => {
      reqs.push(
        addEdit({ ...data, ...e }, false).then((par) => {
          return { par: par.value, gtel: e.gtel }
        })
      )
    })
    let pars = await Promise.all(reqs)

    let parsnos = pars.filter((e) => {
      return e.par != 'succeed'
    })
    commit('delChargeCarSearch')
    if (parsnos.length == 0) {
      ElMessage({
        message: '操作成功',
        type: 'success',
      })
      return { value: 'succeed' }
    } else {
      let str = parsnos
        .map((e) => {
          return e.gtel
        })
        .toString()
      ElMessage({
        message: `以下设备添加失败${str}`,
        type: 'error',
      })
    }
  },
  async chargecaredt({ commit }, data = {}) {
    return await edtEdit(data).then((par) => {
      if (par.value == 'succeed') {
        commit('delChargeCarSearch')
      }
      return par
    })
  },
  async ChargeCardelsfn({ commit }, data = []) {
    let reqs = []
    data.forEach((e) => {
      reqs.push(request('/ChargeCardel', { gtel: e.gtel }))
    })
    await Promise.all(reqs)
    commit('delChargeCarSearch')
  },
  async re() {},
}

export default { state, getters, mutations, actions }
