import request from '@/z/callback/request'
import qs from 'qs'
const state = {
  request: '',
  requests: {},
  requestfalgs: {},
}

const getters = {
  request: (state) => state.request,
}

const mutations = {
  setRequest(state, request) {
    if (request) {
      state.request = request
    } else {
      state.request = []
    }
  },
  setrequestfalgs(state, key) {
    state.requestfalgs[key] = true
  },
  setrequests(state, { key, reqdata }) {
    state.requests[key] = reqdata
  },
  delrequests(state) {
    state.requests = {}
    state.requestfalgs = {}
  },
}

const actions = {
  setRequest({ commit }, request) {
    commit('setRequest', request)
  },
  async getRequest({ commit, state, dispatch }, { url = '', data = {} }) {
    let key = `${url}${qs.stringify(data)}`
    if (state.requests[key]) {
      return state.requests[key]
    }
    if (state.requestfalgs[key]) {
      return await new Promise((res) => {
        setTimeout(() => {
          res(dispatch('getRequest', { url, data }))
        }, 100)
      })
    }
    commit('setrequestfalgs', key)
    let reqdata = await request(url, data)
    commit('setrequests', { key, reqdata })
    return reqdata
  },
  // async getrequestnum(url, data, num = 1000, datas = []) {
  // let zgetdata = []
  // for (let index = datas.length + 1; index <= num + datas.length; index++) {
  //   const element = array[index]
  // }
  // },
}

export default { state, getters, mutations, actions }
