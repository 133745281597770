export default [
  { zh: '查看二维码', en: 'View QR code' },
  { zh: '请输入用户名', en: 'enter one user name' },
  { zh: '记住密码', en: 'Remember the password' },
  { zh: '忘记密码', en: 'Forget password' },
  { zh: '智能充电管理系统', en: 'Intelligent charging management system' },
  { zh: '请打开公众号', en: 'Please open the official account.' },
  { zh: '扫一扫登录', en: 'Scan login' },
  { zh: '版权所有', en: 'copyright' },
  { zh: '手机号', en: 'cell-phone number' },
  { zh: '短信验证码', en: 'SMS verification code' },
  { zh: '浙', en: 'Zhejiang' },
  { zh: '备', en: 'prepare' },
  { zh: '号', en: 'number' },
  { zh: '占位符', en: 'placeholder ' },
  { zh: '用', en: 'use' },
  { zh: '户', en: 'household' },
  { zh: '名', en: 'name' },
  { zh: '密', en: 'dense' },
  { zh: '码', en: 'code' },
  { zh: '登录', en: 'Sign in' },
  { zh: '请输入密码', en: 'Please input a password' },
  { zh: '退出登录', en: 'Log out' },
  { zh: '设备管理', en: 'device management' },
  { zh: '屏幕管理', en: 'Screen management' },
  { zh: '首页', en: 'home page' },
  { zh: '充电桩管理', en: 'Charge point management' },
  { zh: '用户管理', en: 'user management ' },
  { zh: '站点管理', en: 'Site management' },
  { zh: '卡管理', en: 'Card management' },
  { zh: '实体卡管理', en: 'Physical card management' },
  { zh: '实体卡用户', en: 'Physical card user' },
  { zh: '虚拟卡用户', en: 'Virtual card user' },
  { zh: '虚拟卡管理', en: 'Virtual card management' },
  { zh: '统计报表', en: 'Statistical report' },
  { zh: '充值统计', en: 'Recharge statistics' },
  { zh: '电表管理', en: 'Electricity meter management' },
  { zh: '摄像头管理', en: 'Camera management' },
  { zh: '设备序列号', en: 'Equipment serial number' },
  { zh: '名称', en: 'name' },
  { zh: '设备状态', en: 'Equipment status' },
  { zh: '全部', en: 'whole' },
  { zh: '离线', en: 'off-line' },
  { zh: '在线', en: 'on-line' },
  { zh: '未上线', en: 'Not online' },
  { zh: '桩号', en: 'Station' },
  { zh: '桩名', en: 'Pile name' },
  { zh: '所属站点', en: 'Site' },
  { zh: '设备类型', en: 'Equipment type' },
  { zh: '二轮充电桩', en: 'Two wheel charging pile' },
  { zh: '汽车充电桩', en: 'Automobile charging pile' },
  { zh: '充电柜', en: 'Charging cabinet' },
  { zh: '地址', en: 'address' },
  { zh: '收费模式', en: 'Charging mode' },
  { zh: '免费', en: 'free' },
  { zh: '电量计费', en: 'Electricity billing' },
  { zh: '固定计费', en: 'Fixed billing' },
  { zh: '阶梯计费', en: 'Step billing' },
  { zh: '起步模式', en: 'Starting mode' },
  { zh: '小时模式', en: 'Hour mode' },
  { zh: '小时阶梯模式', en: 'Hour ladder mode' },
  { zh: '小时退费', en: 'Hourly refund' },
  { zh: '阶梯退费', en: 'Step refund' },
  { zh: '阶梯金额', en: 'Step amount' },
  { zh: '服务费模式', en: 'Service fee model' },
  { zh: '充电柜租置', en: 'Rental of charging cabinet' },
  { zh: '备注', en: 'remarks' },
  { zh: '电费金额', en: 'Electricity amount' },
  { zh: '照片', en: 'Photo' },
  { zh: '充电时长', en: 'Charging time' },
  { zh: '刷卡时长', en: 'Card swiping time' },
  { zh: '最低费用', en: 'Minimum cost' },
  { zh: '添加时间', en: 'Add time' },
  { zh: '刷卡类型', en: 'Card type' },
  { zh: '网络类型', en: 'Network type' },
  { zh: '设备型号', en: 'Equipment model' },
  { zh: '在线时长', en: 'Online duration' },
  { zh: '抄表信息', en: 'Meter reading information' },
  { zh: '搜索', en: 'search' },
  { zh: '清空', en: 'empty' },
  { zh: '导出', en: 'export' },
  { zh: '添加', en: 'add to' },
  { zh: '批量添加', en: 'Batch add' },
  { zh: '操作', en: 'operation' },
  { zh: '参数设置', en: 'Parameter setting' },
  { zh: '展开', en: 'open' },
  { zh: '合并', en: 'merge' },
  { zh: '远程读表', en: 'Remote meter reading' },
  { zh: '获取验证码', en: 'Get verification code' },
  { zh: '请选择', en: 'Please select' },
  { zh: '区域选择', en: 'Region selection' },
  { zh: '取消', en: 'cancel' },
  { zh: '确定', en: 'determine' },
  { zh: '区域名称', en: 'Area name' },
  { zh: '天', en: 'day' },
  { zh: '小时', en: 'hour' },
  { zh: '未知', en: 'unknown' },
  { zh: 'IC卡', en: 'IC card' },
  { zh: '提示', en: 'Tips' },
  { zh: '导出选中数据', en: 'Export selected data' },
  { zh: '元', en: 'element' },
  { zh: '分钟/1元', en: 'Minutes / 1 yuan' },
  { zh: '添加设备', en: 'Add device' },
  { zh: '选择Exel', en: 'Select Exel' },
  { zh: '请输入桩号', en: 'Please enter a station' },
  { zh: '请选择所属站点', en: 'Please select your site' },
  { zh: '充电时长错误', en: 'Charging time error' },
  { zh: '刷卡时长错误', en: 'Wrong card swiping time' },
  { zh: '最低费用错误', en: 'Minimum cost error' },
  { zh: '请选择Exel', en: 'Please select Exel' },
  { zh: '下载设备二维码', en: 'Download device QR code' },
  { zh: '下载通道二维码', en: 'Download channel QR code' },
  { zh: '关闭', en: 'close' },
  { zh: '充电桩', en: 'Charging pile' },
  { zh: '路', en: 'road' },
  { zh: '编辑', en: 'edit' },
  { zh: '联系人', en: 'contacts' },
  { zh: '联系电话', en: 'contact number' },
  {
    zh: '此设备无需参数配置',
    en: 'This device does not require parameter configuration',
  },
  { zh: 'A表电量', en: 'A meter electricity' },
  { zh: 'B表电量', en: 'B meter electricity' },
  { zh: '第1路', en: 'Route 1' },
  { zh: '空闲', en: 'free' },
  { zh: '被占用', en: 'Occupied' },
  { zh: '故障', en: 'fault' },
  { zh: '第2路', en: '2nd way' },
  { zh: '设备配置', en: 'Equipment configuration' },
  {
    zh: '设备不支持扫码充电',
    en: 'The device does not support code scanning charging',
  },
  { zh: '正常使用', en: 'Normal use' },
  { zh: '系统升级中', en: 'System upgrade in progress' },
  { zh: '设备维护中', en: 'Equipment maintenance in progress' },
  { zh: '只支持微信', en: 'Only wechat is supported' },
  { zh: '只支持支付宝', en: 'Only support Alipay' },
  { zh: '摄像头', en: 'camera' },
  { zh: '无', en: 'nothing' },
  { zh: '桩号翻译', en: 'Station translation' },
  { zh: '桩名翻译', en: 'Pile name translation' },
  { zh: '摄像头翻译', en: 'Camera translation' },
  { zh: '无翻译', en: 'No translation' },
  {
    zh: '当前设备不支持远程抄表',
    en: 'The current device does not support remote meter reading',
  },
  { zh: '电量', en: 'Electric quantity' },
  { zh: '费用', en: 'cost' },
  {
    zh: '当前设备不在线无法抄表',
    en: 'The current device is not online and cannot read meter',
  },
  { zh: '用户名', en: 'user name' },
  { zh: '用户类型', en: 'customer type' },
  { zh: '代理商', en: 'agent' },
  { zh: '安装员', en: 'Installer' },
  { zh: '查看员', en: 'Inspector' },
  { zh: '办卡人员', en: 'Card handling personnel' },
  { zh: '财务人员', en: 'financial staff' },
  { zh: '商家', en: 'business' },
  { zh: '自助注册用户', en: 'Self registered user' },
  { zh: '维护账户', en: 'Maintenance account' },
  { zh: '维修查看员', en: 'Maintenance inspector' },
  { zh: '自定义用户', en: 'Custom user' },
  { zh: '合作伙伴', en: 'cooperative partner' },
  { zh: '物业', en: 'Property' },
  { zh: '优惠卡管理员', en: 'Preferential card administrator' },
  { zh: '销售总监', en: 'Sales Director' },
  { zh: '销售员', en: 'salesperson' },
  { zh: '合作商审批员', en: 'Partner approver' },
  { zh: '工程勘测员', en: 'Engineering surveyor' },
  { zh: '工程施工员', en: 'Engineering constructor' },
  { zh: '工程审核员', en: 'Engineering auditor' },
  { zh: '电话号码', en: 'Telephone number' },
  { zh: '请输入旧密码', en: 'Please enter your old password' },
  { zh: '请输入新密码', en: 'Please enter a new password' },
  { zh: '请再次输入密码', en: 'Please enter the password again' },
  { zh: '旧密码', en: 'Old password' },
  { zh: '新密码', en: 'New password' },
  { zh: '确认密码', en: 'Confirm password' },
  { zh: '查看详情', en: 'View details' },
  { zh: '修改密码', en: 'Change Password' },
  { zh: '权限管理', en: 'Authority management' },
  { zh: '区域配置', en: 'Regional Configuration' },
  { zh: '密码最少为6位', en: 'The password must be at least 6 digits' },
  { zh: '密码', en: 'password' },
  { zh: '用户等级', en: 'User level' },
  { zh: '超级用户', en: 'Super user' },
  { zh: '一级用户', en: 'Primary user' },
  { zh: '二级用户', en: 'Secondary user' },
  { zh: '三级用户', en: 'Tertiary user' },
  { zh: '分润', en: 'share in the benefit or profit' },
  { zh: '不设置', en: 'Not set' },
  { zh: '用户添加', en: 'User add' },
  { zh: '密码修改', en: 'Password modification' },
  { zh: '显示电量', en: 'Display power' },
  { zh: '今日收入', en: 'Income today' },
  { zh: '总收入', en: 'Total revenue' },
  { zh: '显示卡', en: 'Display card' },
  { zh: '显示消息', en: 'display messages' },
  { zh: '显示虚拟卡', en: 'Display virtual card' },
  { zh: '给卡充值', en: 'Recharge the card' },
  { zh: '编辑设备', en: 'Edit device' },
  { zh: '删除设备', en: 'Delete device' },
  { zh: '充电记录', en: 'Charging record' },
  { zh: '收入统计', en: 'Income statistics' },
  { zh: '转账统计', en: 'Transfer statistics' },
  { zh: '用户列表', en: 'User list' },
  { zh: '卡充值统计', en: 'Card recharge statistics' },
  { zh: '日收入统计', en: 'Daily income statistics' },
  { zh: '区域管理', en: 'Regional management' },
  { zh: '应该在0到100之间', en: 'Should be between 0 and 100' },
  { zh: '建站时间', en: 'Station construction time' },
  { zh: '站点', en: 'site' },
  { zh: '上级站点', en: 'Parent site' },
  { zh: '独立分润', en: 'Independent profit sharing' },
  { zh: '不支付', en: 'No payment' },
  { zh: '支付', en: 'payment' },
  { zh: '日租费', en: 'Daily rent' },
  { zh: '日租费收款方', en: 'Payee of daily rent' },
  { zh: '平台', en: 'platform' },
  { zh: '测试', en: 'test' },
  { zh: '内部测试2-代理商', en: 'Internal test 2 - agent' },
  { zh: '内部测试2-物业', en: 'Internal test 2 - property' },
  { zh: '默认流量费', en: 'Default traffic fee' },
  { zh: '流量费收款方', en: 'Flow fee payee' },
  { zh: '测试-一级分润', en: 'Test - primary profit distribution' },
  { zh: '管理员姓名', en: 'Administrator name' },
  { zh: '管理员电话', en: 'Administrator phone' },
  { zh: '充电统计', en: 'Charging statistics' },
  { zh: '卡充电记录', en: 'Card charging record' },
  { zh: '卡赠送统计', en: 'Card gift statistics' },
  { zh: '站点流水', en: 'Site flow' },
  { zh: '离线统计', en: 'Offline statistics' },
  { zh: '人员统计', en: 'Personnel statistics' },
  { zh: '用户充电记录', en: 'User charging record' },
  { zh: '卡余额统计', en: 'Card balance statistics' },
  { zh: '区域站点流水', en: 'Regional site flow' },
  { zh: '流量卡充值统计', en: 'Flow card recharge statistics' },
  { zh: '0元桩统计', en: '0 yuan pile statistics' },
  { zh: '插座故障统计', en: 'Socket failure statistics' },
  { zh: '分润设置', en: 'Profit distribution setting' },
  { zh: '修改', en: 'modify' },
  { zh: '请输入站点', en: 'Please enter a site' },
  { zh: '站点添加', en: 'Site addition' },
  { zh: '站点详情', en: 'Site details' },
  { zh: '预留电话', en: 'Reserved telephone' },
  { zh: '请输入验证码', en: 'Please enter the verification code' },
  { zh: '验证码错误', en: 'Verification code error' },
  { zh: 'mj', en: 'mj' },
  { zh: '预留电话翻译', en: 'Reserved telephone translation' },
  { zh: '验证码', en: 'Verification Code' },
  { zh: '站点配置', en: 'Site configuration' },
  { zh: '删除', en: 'delete' },
  { zh: '账户管理', en: 'Account management' },
  { zh: '备注信息', en: 'Remark information' },
  { zh: '广告管理', en: 'Advertising management' },
  { zh: '广告1图片', en: 'Advertisement 1 picture' },
  { zh: '广告1跳转地址', en: 'Ad 1 jump address' },
  { zh: '广告2图片', en: 'Advertisement 2 picture' },
  { zh: '广告2跳转地址', en: 'Ad 2 jump address' },
  { zh: '广告3图片', en: 'Advertisement 3 picture' },
  { zh: '广告3跳转地址', en: 'Ad 3 jump address' },
  { zh: '广告4图片', en: 'Advertising 4 pictures' },
  { zh: '广告4跳转地址', en: 'Ad 4 jump address' },
  { zh: '广告5图片', en: 'Advertising 5 pictures' },
  { zh: '广告5跳转地址', en: 'Ad 5 jump address' },
  { zh: '充电车辆广告', en: 'Charging vehicle advertising' },
  { zh: '跳转地址', en: 'Jump address' },
  { zh: '充电车辆广告2', en: 'Charging vehicle advertisement 2' },
  { zh: '充电车辆广告3', en: 'Charging vehicle advertisement 3' },
  { zh: '充电车辆广告4', en: 'Charging vehicle advertising 4' },
  { zh: '充电车辆广告5', en: 'Charging vehicle advertising 5' },
  { zh: '开屏广告', en: 'Open screen advertising' },
  { zh: '广告时长', en: 'Advertising duration' },
  { zh: '熄屏广告', en: 'Off screen advertising' },
  { zh: '保存分润设置', en: 'Save profit distribution settings' },
  { zh: '备注查看', en: 'Comments view' },
  { zh: '分润方选择', en: 'Selection of profit distributor' },
  { zh: '添加分润方', en: 'Add profit distributor' },
  { zh: '确定分润方', en: 'Determine the profit distributor' },
  { zh: '添加新分润用户', en: 'Add new profit sharing user' },
  { zh: '百分比', en: 'percentage' },
  { zh: '分润方', en: 'Fen run Fang' },
  { zh: '电表名称', en: 'Meter name' },
  { zh: '时间', en: 'time' },
  { zh: 'title', en: 'title' },
  { zh: '屏幕添加', en: 'Screen add' },
  { zh: '屏幕查看', en: 'Screen view' },
  { zh: '摄像头IP', en: 'CAMERA IP' },
  { zh: '硬件厂家', en: 'Hardware manufacturer' },
  { zh: '海康威视', en: 'Hikvision' },
  { zh: '萤石', en: 'fluorite' },
  { zh: '摄像头端口', en: 'Camera port' },
  { zh: '摄像头用户名', en: 'Camera user name' },
  { zh: '摄像头密码', en: 'Camera password' },
  { zh: '摄像头类型', en: 'Camera type' },
  { zh: '通道号', en: 'Channel number' },
  { zh: '播放地址', en: 'Playback address' },
  { zh: '时间选择', en: 'Time selection' },
  { zh: '结束日期', en: 'End date' },
  { zh: '至', en: 'to' },
  { zh: '开始日期', en: 'Start date' },
  { zh: '订单号', en: 'order number' },
  { zh: '充值时间', en: 'Recharge time' },
  { zh: '金额(0)', en: 'Amount (0)' },
  { zh: '金额(717.98)', en: 'Amount (717.98)' },
  { zh: '支付类型', en: 'Payment type' },
  { zh: '微信支付', en: 'Wechat payment' },
  { zh: '支付宝支付', en: 'Alipay payment' },
  { zh: '金额', en: 'amount of money' },
  { zh: '用户昵称', en: 'User nickname' },
  { zh: '微信用户', en: 'Wechat users' },
  { zh: '支付宝用户', en: 'Alipay users' },
  { zh: '刷卡用户', en: 'Card user' },
  { zh: '次卡用户', en: 'Sub card user' },
  { zh: '用户头像', en: 'User Avatar' },
  { zh: '卡号', en: 'Card number' },
  { zh: '印刷卡号', en: 'Printed card number' },
  { zh: '卡类型', en: 'Card type' },
  { zh: '状态', en: 'state' },
  { zh: '站点消费卡', en: 'Site consumption card' },
  { zh: '时间月卡', en: 'Time monthly card' },
  { zh: '充值详情', en: 'Recharge details' },
  { zh: '是否意外断电', en: 'Is there an accidental power failure' },
  { zh: '正常充电', en: 'Normal charging' },
  { zh: '未完全充电', en: 'Not fully charged' },
  { zh: '异常断开', en: 'Abnormal disconnection' },
  { zh: '未退款', en: 'No refund' },
  { zh: '已退款', en: 'Refunded ' },
  { zh: '刷卡支付', en: 'Card payment' },
  { zh: '扫码支付', en: 'Code scanning payment' },
  { zh: '投币', en: 'coin-operated' },
  { zh: '云闪付', en: 'Cloud flash payment' },
  { zh: '充电时间', en: 'Charging time' },
  { zh: '结束时间', en: 'End time' },
  { zh: '退款记录', en: 'Refund record' },
  { zh: '总时长', en: 'Total duration' },
  { zh: '充电模式', en: 'Charging mode' },
  { zh: '初始功率', en: 'Initial power' },
  { zh: '结束原因', en: 'End reason' },
  { zh: '查看电流图', en: 'View current diagram' },
  { zh: '通道', en: 'passageway' },
  { zh: '预估电量', en: 'Estimated electricity' },
  { zh: '开始时间', en: 'start time' },
  { zh: '今日', en: 'today' },
  { zh: '本月', en: 'This month' },
  { zh: '上月', en: 'Last month' },
  { zh: '充电详情', en: 'Charging details' },
  { zh: '费率', en: 'rate ' },
  { zh: '充电用户', en: 'Charging user' },
  { zh: '注册时间', en: 'Registration time' },
  { zh: '头像', en: 'head portrait' },
  { zh: '电流图表', en: 'Current chart' },
  { zh: '消费卡', en: 'Consumer card' },
  { zh: '次数卡', en: 'Frequency card' },
  { zh: '月卡', en: 'Monthly card' },
  { zh: '整月', en: 'whole month' },
  { zh: '到期卡', en: 'Expiration card' },
  { zh: '余额', en: 'balance' },
  { zh: '用户姓名', en: 'User name' },
  { zh: '类型', en: 'type' },
  { zh: '交易号', en: 'Transaction number' },
  { zh: '支付金额', en: 'Payment amount' },
  { zh: '操作用户', en: 'Operating user' },
  { zh: '赠送金额', en: 'Gift amount' },
  { zh: '操作类型', en: 'Operation type' },
  { zh: '激活后赠送的金额', en: 'Amount given after activation' },
  { zh: '未激活前赠送金额', en: 'Gift amount before activation' },
  { zh: '保险费用', en: 'Insurance premium' },
  { zh: '离线次数', en: 'Offline times' },
  { zh: '卡状态', en: 'Card status' },
  { zh: '挂失', en: 'report the loss' },
  { zh: '正常', en: 'normal' },
  { zh: '使用站点', en: 'Use site' },
  { zh: '恢复正常', en: 'Return to normal' },
  { zh: '更换卡号', en: 'Replace card number' },
  { zh: '确认修改', en: 'Confirm modification' },
  { zh: '卡挂失', en: 'Card loss report' },
  { zh: '确认添加', en: 'Confirm add' },
  { zh: '请输入用户电话', en: 'Please enter user phone' },
  { zh: '用户电话', en: 'Subscriber telephone' },
  { zh: '卡内余额', en: 'Card balance' },
  { zh: '有效期', en: 'term of validity' },
  { zh: '充值金额', en: 'Recharge amount' },
  { zh: '充值卡号', en: 'Recharge card number' },
  { zh: '解绑卡号', en: 'Unbinding card number' },
  { zh: '充值记录', en: 'Recharge record' },
  { zh: '卡充值信息', en: 'Card recharge information' },
  { zh: '默认金额', en: 'Default amount' },
  { zh: '月', en: 'month' },
  { zh: '开始卡号', en: 'Start card number' },
  { zh: '结束卡号', en: 'End card number' },
  { zh: '确认注销', en: 'Confirm logout' },
  { zh: '故障次数', en: 'Number of failures' },
  { zh: '今天', en: 'today' },
  { zh: '昨天', en: 'yesterday' },
  { zh: '前天', en: 'The day before yesterday' },
  { zh: '三天内', en: 'Within three days' },
  { zh: '续费年限', en: 'Renewal period' },
  { zh: '充值状态', en: 'Recharge status' },
  { zh: '续费用户', en: 'Renewal user' },
  { zh: '创建时间', en: 'Creation time' },
  { zh: '总电量', en: 'Total electricity' },
  { zh: '总费率', en: 'Total rate' },
  { zh: '用户选择', en: 'User selection' },
  { zh: '支付宝账号', en: 'Alipay account' },
  { zh: '支付宝账号姓名', en: 'Alipay account name' },
  { zh: '支付原因', en: 'Reason for payment' },
  { zh: '支付时间', en: 'Payment time' },
  { zh: '离线统计详情', en: 'Offline statistics details' },
  { zh: '离线时间', en: 'Offline time' },
  { zh: '批量删除', en: 'Batch delete' },
  { zh: '次', en: 'second' },
  { zh: '未激活', en: 'not active' },
  { zh: '已激活', en: 'Activated' },
  { zh: '用户名不能为空', en: 'User name cannot be empty' },
  { zh: '密码不能为空', en: 'Password cannot be empty' },

  //
]
