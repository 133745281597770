import request from '@/z/callback/request'
import { ElMessage } from 'element-plus'
import parfn from '@/utils/par'
export default async function baserequest(url, data = {}) {
  let par = await request(url, data)
  par = parfn(par)
  ElMessage({
    message: par.label,
    type: par.value == 'succeed' ? 'success' : 'error',
  })
  return par
}
