export default function searchfn(inputs, formdata, datas) {
  console.log(formdata, datas, inputs)
  return datas.filter((e) => {
    let array = inputs
    for (let index = 0; index < array.length; index++) {
      const element = array[index]
      if (formdata[element.value] != '') {
        if (element.type == 'select' || element.type == 'pcs') {
          return e[element.value] == formdata[element.value]
        } else {
          return e[element.value].indexOf(formdata[element.value]) >= 0
        }
      }
    }
    return true
  })
}
