import { createRouter, createWebHashHistory } from 'vue-router'

import authentication from './routerjson'

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: authentication,
})

export default router
