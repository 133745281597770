// import timeFormat from './timeFormat'
import timeFormat from './timeFormat.js'
class timefn {
  constructor() {}
  gettime(num = 0, date = new Date(), format = 'yyyy-mm-dd') {
    let dateTime = Number(new Date(date))
    dateTime = dateTime - Number(num) * 24 * 60 * 60 * 1000
    return timeFormat(dateTime, format)
  }
  getdateTime(date) {
    if (!date || date == 'undefined') {
      return new Date().getTime()
    }
    return new Date(Date.parse(date.replace(/-/g, '/'))).getTime()
  }
  getUTCtime(dateTime1, dateTime2, r = 60) {
    let dateTime1num = this.getdateTime(String(dateTime1))
    let dateTime2num = this.getdateTime(String(dateTime2))

    let timer = Math.ceil(
      (Number(dateTime2num) - Number(dateTime1num)) / 1000 / r
    )
    return timer
  }
  gettimemm(num = 0, format = 'yyyy-mm-dd') {
    let dateTime = new Date()
    dateTime.setDate(1)
    dateTime.setMonth(dateTime.getMonth() - num)
    return timeFormat(new Date(dateTime), format)
  }
}
// let time = new timefn()
// console.log(time.gettimemm(100))
export default new timefn()
