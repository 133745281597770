import { selectzfn } from '@/utils/base'
export default function tablestr(input = {}, data = {}) {
  if (input.callbackasync) {
    return data[Symbol.for(input.value)]
  }
  if (input.callback) {
    return input.callback(data)
  }
  if (input.type == 'select') {
    return selectzfn(input, data)
  }
  if (input.type == 'select') {
    return selectzfn(input, data)
  }

  let r = data[input.value] ? data[input.value] : ''
  if (input.fl) {
    r = parseFloat(r).div(input.fl)

    if (isNaN(r)) {
      r = '0'
    }
    r = r.toString()
  }
  return r
}
tablestr.prototype.tablestrasync = async function (input = {}, data = {}) {
  if (input.callbackasync) {
    return await input.callbackasync(data)
  }
  if (input.callback) {
    return input.callback(data)
  }
  if (input.type == 'select') {
    return selectzfn(input, data)
  }
  if (input.type == 'select') {
    return selectzfn(input, data)
  }

  let r = data[input.value] ? data[input.value] : ''
  if (input.fl) {
    r = parseFloat(r).div(input.fl)

    if (isNaN(r)) {
      r = '0'
    }
    r = r.toString()
  }
  return r
}
