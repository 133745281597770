import request from '@/z/callback/request'
import qs from 'qs'
const state = {
  statfr: '',
  statfrs: new Map(),
  statfrsfalg: new Map(),
  StatFrPre: new Map(),
  StatFrPrefalg: new Map(),
}

const getters = {
  statfr: (state) => state.statfr,
}

const mutations = {
  setStatfr(state, statfr) {
    if (statfr) {
      state.statfr = statfr
    } else {
      state.statfr = []
    }
  },
  setstatfrs(state, { key, value }) {
    state.statfrs.set(key, value)
  },
  setstatfrsfalg(state, { key, value }) {
    state.statfrsfalg.set(key, value)
  },
  setStatFrPre(state, { key, value }) {
    state.StatFrPre.set(key, value)
  },
  setStatFrPrefalg(state, { key, value }) {
    state.StatFrPrefalg.set(key, value)
  },
  delstatfr(state) {
    state.statfrs.clear()
    state.statfrsfalg.clear()
  },
}

const actions = {
  setStatfr({ commit }, statfr) {
    commit('setStatfr', statfr)
  },
  async getstatfrone({ state, commit, dispatch }, pcs) {
    if (!pcs) {
      return []
    }
    let str = qs.stringify(pcs)

    if (state.statfrs.has(str)) {
      return state.statfrs.get(str)
    }
    if (state.statfrsfalg.has(str)) {
      return await new Promise((res) => {
        setTimeout(() => {
          res(dispatch('getstatfrone', pcs))
        }, 100)
      })
    }
    commit('setstatfrsfalg', { key: str, value: [] })
    let r = await request('StatFr', pcs)
    commit('setstatfrs', { key: str, value: r })
    return r
    // statfrs
  },
  async StatFrPre({ state, commit, dispatch }, data) {
    if (!data) {
      return []
    }
    let str = qs.stringify(data)

    if (state.StatFrPre.has(str)) {
      return state.StatFrPre.get(str)
    }

    if (state.StatFrPrefalg.has(str)) {
      return await new Promise((res) => {
        setTimeout(() => {
          res(dispatch('StatFrPre', data))
        }, 100)
      })
    }
    commit('setStatFrPrefalg', { key: str, value: [] })
    let r = await request('StatFrPre', data)
    commit('setStatFrPre', { key: str, value: r })
    return r
  },
  async StatFredt({ commit }, data) {
    let r = await request('/StatFredt', data)
    commit('delstatfr')
    return r
  },
}

export default { state, getters, mutations, actions }
