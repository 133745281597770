<template>
  <div class="Zheader-container">
    <div class="title">杭州市充电桩管理平台</div>

    <!-- {{ UPW }} -->
    <div class="baseflex">
      <div
        class="iconfont textrpadding"
        style="color: #ffffff; font-size: 36px"
      >
        &#xe613;
      </div>
      <span class="textrpadding usertext">
        {{ USearch.uUser }}
        <span class="textrpadding"></span>
        <!-- 管理员，你好! -->
      </span>
      <span
        class="iconfont pointer"
        style="color: #ffffff; font-size: 36px"
        @click="LoginOutfn()"
      >
        &#xe690;
      </span>
    </div>
    <audio ref="audio" :src="audiosrc"></audio>
  </div>
</template>

<script>
  export default {
    name: 'Zheader',
    components: {},
    data() {
      return {
        audiosrc: '',
        timer: '',
        timestr: '',
        StatIndexstr: '',
        StatIndexstricon: '',
        Stationstr: '',
        contentstyle: [],
        GetAlCurold: [],
        ChargeCar: [],
        GetAlCurfalg: false,
      }
    },
    computed: {
      UPW() {
        return this.$store.state.upw.upw
      },
      USearch() {
        return this.$store.state.user.USearch
      },
      Stationstrfalg() {
        return this.$route.path == '/Situation/Situation1' ? true : false
      },
    },
    created() {},
    mounted() {
      // this.getdata()
    },
    beforeUnmount() {
      // timer
      clearInterval(this.GetAlCurInterval)
      console.log('销毁')
    },
    methods: {
      async LoginOutfn() {
        await this.$z.request('/LoginOut')
        // window.location.href = './index.html'
        this.$router.push({ path: '/login' })
        setTimeout(() => {
          window.location.reload()
        }, 100)
      },
      GetAlCurtap() {
        this.GetAlCurfalg = !this.GetAlCurfalg
        clearInterval(this.GetAlCurInterval)
        if (this.GetAlCurfalg) {
          this.GetAlCurInterval = setInterval(() => {
            this.GetAlCurfn()
          }, 3000)
        }
      },
      async getdata() {
        if (this.UPW.b_index == 1) {
          this.timestr = this.timestrfn()
          this.timer = setInterval(() => {
            this.timestr = this.timestrfn()
          }, 1000)
          let StatIndex = await this.$z.request('/StatIndex', {
            pcs: this.USearch.pcs,
          })
          if (StatIndex.length == 1) {
            StatIndex = StatIndex[0]
            this.StatIndexstr = `${StatIndex.weather1}        ${StatIndex.temperature1}`
            this.StatIndexstricon = this.tqiconfn(StatIndex)
          }
        } else {
          this.GetAlCurtap()
          let Station = await this.$z.request('/Station')
          this.Stationstr = Station.map(function (e) {
            return e.pcs
          }).join('  ')
          setTimeout(() => {
            let w1 = this.$refs.demo2box.offsetWidth
            let w2 = this.$refs.noticebarcontent.offsetWidth
            if (w2 > w1) {
              this.contentstyle = [{ animationDuration: `${(w2 / w1) * 10}s` }]
            }
          }, 100)
        }
      },
      timestrfn() {
        var now = new Date()
        var year = now.getFullYear()
        var month =
          now.getMonth() + 1 < 10
            ? '0' + (now.getMonth() + 1)
            : now.getMonth() + 1
        var day = now.getDate() < 10 ? '0' + now.getDate() : now.getDate()
        var h = now.getHours() < 10 ? '0' + now.getHours() : now.getHours()
        var m =
          now.getUTCMinutes() < 10
            ? '0' + now.getUTCMinutes()
            : now.getUTCMinutes()
        var s =
          now.getUTCSeconds() < 10
            ? '0' + now.getUTCSeconds()
            : now.getUTCSeconds()
        var dateStr = `${h}:${m}:${s} ${year}-${month}-${day}`
        return dateStr
      },
      tqiconfn(p) {
        p.weather1 = p.weather1 ? p.weather1 : ''
        if (p.weather1.indexOf('晴') >= 0) {
          return '&#xe682;'
        }
        if (p.weather1.indexOf('雨') >= 0) {
          return '&#xe684;'
        }
        if (p.weather1.indexOf('云') >= 0) {
          return '&#xe681;'
        }

        if (p.weather1.indexOf('雷') >= 0) {
          return '&#xe685;'
        }
      },
      GetAlCurstypefn(p) {
        var types = {
          1: '短路报警',
          2: '烟雾报警',
          3: '充电异常报警',
          4: '温度报警',
          8: '继电器故障报警',
          12: '线缆温度报警',
        }
        return types[p.type]
      },
      async GetAlCurfn() {
        let GetAlCur = await this.$z.request('/GetAlCur')
        GetAlCur = GetAlCur.filter((ez) => {
          if (
            (ez.type == '3' &&
              this.USearch.uUser != '玉林市电动车充电桩管理平台') ||
            ez.type == '8' ||
            !this.GetAlCurstypefn(ez)
          ) {
            return false
          } else if (ez.type == '12') {
            ez.td1 = parseInt(ez.td) + 1
            return parseInt(ez.td) % 2 != 0
          } else {
            return true
          }
        })
        GetAlCur = GetAlCur.filter((e) => {
          return this.GetAlCurold.find((fe) => {
            return (
              e.sjc == fe.sjc &&
              e.gtel == fe.gtel &&
              e.td == fe.td &&
              e.type == fe.type
            )
          })
            ? false
            : true
        })

        if (this.GetAlCurold.length == GetAlCur.length) {
          return
        }
        this.GetAlCurold = this.$z.deepClone(GetAlCur)
        console.log(GetAlCur, 'GetAlCurGetAlCurGetAlCurGetAlCur')
        if (GetAlCur.length != 0) {
          // audio
          if (
            GetAlCur[0].type == 2 ||
            GetAlCur[0].type == 3 ||
            GetAlCur[0].type == 4
          ) {
            this.audiosrc = `../wma/GetAlCur${GetAlCur[0].type}.mp3`
            setTimeout(() => {
              this.$refs.audio.play()
            }, 500)
          }
          let ChargeCarmc = await this.$z.request('/ChargeCar', {
            gtel: GetAlCur[0].gtel,
          })
          ChargeCarmc = ChargeCarmc.length == 1 ? ChargeCarmc[0].mc : ''
          let title = this.GetAlCurstypefn(GetAlCur[0])
          let messagestr = `<div>桩名：${ChargeCarmc}</div><div>桩号：${GetAlCur[0].gtel}</div>`
          if (GetAlCur[0].type != 2 && GetAlCur[0].type != 12) {
            messagestr += '<div>'
            messagestr += ` 通道：${GetAlCur[0].td}`
            if (GetAlCur[0].type == 3) {
              messagestr += `  电流：${GetAlCur[0].dl / 10}A`
            }
            messagestr += '</div>'
          }
          if (GetAlCur[0].type == 12) {
            messagestr += `<div>通道：${GetAlCur[0].td}${GetAlCur[0].td1}  当前温度：${GetAlCur[0].dl}°C</div>`
          }
          let notify = this.$notify({
            title,
            dangerouslyUseHTMLString: true,
            message: messagestr,
            position: 'bottom-right',
            type: 'error',
            duration: 60000,
            onClick: () => {
              notify.close()
              this.$router.push({
                path: `/ChargeCardt`,
                query: {
                  lx: 'GetAlCur',
                  type: GetAlCur[0].type,
                  td: GetAlCur[0].td,
                  gtel: GetAlCur[0].gtel,
                  sjc: 'Y',
                },
              })
            },
          })
        }

        console.log(GetAlCur)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .title {
    font-size: 30px;
    font-weight: bold;
    line-height: 64px;
    color: #2093f5;
    text-shadow: 4px 7px 10px #000;
  }
  .textrpadding {
    padding-right: 20px;
  }
  .usertext {
    font-size: 16px;
  }
  .indexstr1 {
    line-height: 18px;
    font-size: 18px;
    margin-bottom: 4px;
  }
  .indexstr2 {
    line-height: 18px;
    font-size: 18px;
    color: #46a6de;
  }
  .notice-bar__wrap {
    position: relative;
    flex: 1;
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 20px;
  }
  .notice-bar__content {
    position: absolute;
    white-space: nowrap;
    transition-timing-function: linear;
    // transition-duration: 8.73333s;
    // transform: translateX(-196px);
    animation: 0s wordsLoop linear infinite normal;
    font-size: 18px;
  }
  @keyframes wordsLoop {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  .GetAlCur {
    color: #f87a7d;
  }
</style>
