import { getList } from '@/api/usearch'
import qs from 'qs'
import request from '@/z/callback/request'
import requestbase from '@/z/callback/requestbase'
const state = {
  usearch: '',
  USearchs: new Set(),
  USearchtables: new Map(),
  USearchAllfrid: [],
}

const getters = {
  usearch: (state) => state.usearch,
}

const mutations = {
  setUsearch(state, usearch) {
    if (usearch) {
      state.usearch = usearch
    } else {
      state.usearch = []
    }
  },
  setUSearchs(state, usearch) {
    state.USearchs = new Set([...state.USearchs, ...usearch])
  },
  setUSearchtables(state, { key, value }) {
    state.USearchtables.set(key, value)
  },
  setUSearchAllfrid(state, data) {
    state.USearchAllfrid = data
  },
  delUSearchtables(state) {
    state.USearchtables.clear()
    state.USearchs.clear()
    state.USearchAllfrid = []
  },
}

const actions = {
  async USearchAllfrid({ state, commit }) {
    if (state.USearchAllfrid.length != 0) {
      return state.USearchAllfrid
    }

    let datas = await request('/USearchAllfrid', { b_pt: 1 })

    commit('setUSearchAllfrid', datas)

    return datas
  },
  setUsearch({ commit }, usearch) {
    commit('setUsearch', usearch)
  },
  async getusearchone({ state, commit }, uUser) {
    if (!uUser) {
      return {}
    }
    let r = [...state.USearchs].find((e) => {
      return e.uUser == uUser
    })

    if (!r) {
      console.log(r, uUser)
      let data = await getList({ uUser: uUser })

      r = data.length == 0 ? {} : data[0]
      commit('setUSearchs', data)
    }
    return r
  },
  async getUSearch({ commit, state }, data) {
    let r = []
    let key = qs.stringify(data)
    if (state.USearchtables.has(key)) {
      r = state.USearchtables.get(key)
    } else {
      r = await request('/USearch', data)
      commit('setUSearchtables', { key: key, value: r })
    }
    return r
  },
  async Udelfn({ commit }, data = []) {
    let reqs = []
    data.forEach((e) => {
      reqs.push(request('/Udel', { uUser: e.uUser }))
    })
    await Promise.all(reqs)
    commit('delUSearchtables')
  },
  async Uedt({ commit }, data) {
    let par = await requestbase('/Uedt', data)
    commit('delUSearchtables')
    return par
  },
  async Uadd({ commit }, data) {
    let par = await requestbase('/Uadd', data)
    commit('delUSearchtables')
    return par
  },
  async Udmaedt({ commit }, data) {
    let par = await requestbase('/Udmaedt', data)
    commit('delUSearchtables')
    return par
  },
}

export default { state, getters, mutations, actions }
