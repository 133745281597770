import requestbase from '@/z/callback/requestbase'
const state = {
  card: '',
}

const getters = {
  card: (state) => state.card,
}

const mutations = {
  setCard(state, card) {
    if (card) {
      state.card = card
    } else {
      state.card = []
    }
  },
}

const actions = {
  setCard({ commit }, card) {
    commit('setCard', card)
  },
  async Cardsedt(store, data) {
    for (let key in data) {
      if (data[key] == '') {
        delete data[key]
      }
    }
    return await requestbase('/Cardsedt', data)
  },
  async CardsPLedt(store, data) {
    return await requestbase('/CardsPLedt', data)
  },
  async Cardedt(store, data) {
    return await requestbase('/Cardedt', data)
  },
  async VCardCZ(store, data) {
    if (data.sjc == '') {
      delete data.sjc
    }
    return await requestbase('/VCardCZ', data)
  },
  //
}

export default { state, getters, mutations, actions }
