import request from '@/z/callback/request'
const state = {
  mpt: '',
  GetMappt: '',
}

const getters = {
  mpt: (state) => state.mpt,
}

const mutations = {
  setMpt(state, mpt) {
    if (mpt) {
      state.mpt = mpt
    } else {
      state.mpt = []
    }
  },
}

const actions = {
  setMpt({ commit }, mpt) {
    commit('setMpt', mpt)
  },
  async getGetMappt({ state }) {
    if (state.GetMappt == '') {
      let GetMappt = await request('/GetMappt')
      state.GetMappt = GetMappt.length == 0 ? {} : GetMappt[0]
    }
    return state.GetMappt
  },
}

export default { state, getters, mutations, actions }
