import request from '@/z/callback/request'
import qs from 'qs'
const state = {
  token: {},
  user: '',
  GetSession: {},
  USearch: {},
  UPW: {},
  indata: {},
}

const getters = {
  token: (state) => state.token,
  user: (state) => state.user,
  loginstatus: (state) => {
    if (
      Object.keys(state.GetSession).length == 0 ||
      state.GetSession.utype == 99
    ) {
      return false
    }
    return true
  },
}

const mutations = {
  setUser(state, user) {
    if (user) {
      state.user = user
    } else {
      state.user = []
    }
  },
  setGetSession(state, GetSession) {
    state.GetSession = GetSession
  },
  setUSearch(state, USearch) {
    state.token = USearch
    state.USearch = USearch
  },
  setUPW(state, UPW) {
    state.UPW = UPW
  },
  setindata(state, key) {
    state.indata[key] = true
  },
}

const actions = {
  setUser({ commit }, user) {
    commit('setUser', user)
  },
  async login(store, data) {
    return await request('/Login', data)
  },
  async GetSession({ commit }) {
    let GetSession = await request('/GetSession')
    GetSession = GetSession.length == 1 ? GetSession[0] : {}
    commit('setGetSession', GetSession)
    return GetSession
  },
  async getUSearch({ state, commit, dispatch }) {
    let indatakey = qs.stringify({
      actions: 'getUSearch',
    })
    if (state.indata[indatakey]) {
      return await new Promise((res) => {
        setTimeout(() => {
          res(dispatch('getUSearch'))
        }, 100)
      })
    }
    commit('setindata', indatakey)
    // console.log(11111111111111, state.indata[indatakey], 222222222, indatakey)
    let GetSession = state.GetSession
    if (Object.keys(GetSession).length == 0) {
      GetSession = await request('/GetSession')
    }
    if (GetSession.utype == 99) {
      return {}
    }
    let USearch = await request('/USearch', { uUser: GetSession.uUser })

    USearch = USearch.length == 1 ? USearch[0] : {}
    commit('setUSearch', USearch)
    return USearch
  },
  async getUPW({ state, commit }) {
    let UPW = await request('/UPW', { uUser: state.GetSession.uUser })
    UPW = UPW.length == 1 ? UPW[0] : {}
    commit('setUPW', UPW)
    return UPW
  },
}

export default { state, getters, mutations, actions }
