// import { filterRoutes } from '@/utils/routes'
// console.log(filterRoutes)
const state = {
  routes: [],
}

const getters = {
  routes: (state) => state.routes,
  menudata: (state) => {
    let router = state.routes || []
    let r = []
    let xtgl = {
      path: '/xtgl',
      meta: { title: '系统管理', icon: '&#xe645;' },
      urls: [],
    }
    let lonelys = [
      '财务管理',
      '合作加盟',
      '售后管理',
      '小区管理',
      '优惠设置',
      '优惠券管理',
      '设备管理',
      '代理商管理',
      '物联网卡管理',
      '商品管理',
    ]
    router.forEach((e) => {
      if (lonelys.indexOf(e.meta.title) < 0 && e.children.length == 1) {
        r.push(e)
      } else {
        xtgl.urls.push(e)
      }
    })
    if (xtgl.urls.length != 0) {
      r.push(xtgl)
    }
    return r
  },
}

const mutations = {
  setRoutes(state, routes) {
    if (routes) {
      state.routes = routes
    } else {
      state.routes = []
    }
  },
}

const actions = {
  setRoutes({ commit }, routes) {
    commit('setRoutes', routes)
  },
}

export default { state, getters, mutations, actions }
