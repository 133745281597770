function pdfn(input, data) {
  if (!(input.value in data)) {
    return false
  }
  return input.zhi.find((e) => {
    return e == data[input.value]
  })
}
// , attr = {}
export default function inputif(input = {}, data = {}) {
  // 目的所有的数据if判断都通过这个方法来返回是否符合要求

  if (input.if) {
    let { zhi = [], zhierror = [], zhimust = [] } = input.if

    let r = zhimust.find((e) => {
      return !pdfn(e, data)
    })

    if (r && zhimust.length != 0) {
      return false
    }
    r = zhierror.find((e) => {
      return pdfn(e, data)
    })
    if (r) {
      return false
    }

    r = zhi.find((e) => {
      return pdfn(e, data)
    })
    if (r || zhi.length == 0) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}
