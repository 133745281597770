import store from '@/store'
import request from '@/z/callback/request'
const state = {
  base: '',
  UToPcsutypes: ['18', '19', '20'],
  UPWutypes: ['0', '12', '18', '19', '20', '4', '3'],
  WpassSms: '',
}

const getters = {
  base: (state) => state.base,
}

const mutations = {
  setBase(state, base) {
    if (base) {
      state.base = base
    } else {
      state.base = []
    }
  },
}

const actions = {
  setBase({ commit }, base) {
    commit('setBase', base)
  },
  async getWpassSms(aa, utel = store.state.user.token.utel) {
    if (utel == '' || !utel) {
      return
    }
    let par = await request('/WpassSms', { utel })
    // [{"par":"111111"}]
    return par[0].par
  },
}

export default { state, getters, mutations, actions }
