import i18n from '@/i18n'
function isArray(arr) {
  return Object.prototype.toString.call(arr) === '[object Array]'
}
// import { createLogger } from 'vuex'

export default function translateTitle(title) {
  const { t, te } = i18n.global
  if (te(`${title}`)) {
    return t(title)
  }
  let titles = String(title).match(/[\u4e00-\u9fa5]+/g)
  if (!isArray(titles) || titles.length == 0) {
    return title
  }
  let r = title,
    ffalg = true
  for (let index = 0; index < titles.length; index++) {
    const element = titles[index]
    if (!te(`${element}`)) {
      ffalg = false
      break
    }
  }
  if (ffalg) {
    titles.forEach((element) => {
      r = r.replace(element, t(element))
    })
    return r
  }
  titles.forEach((element) => {
    r = r.replace(element, element)
  })
  return r
}
