/**
 * @description 路由守卫，目前两种模式：all模式与intelligence模式
 */
import store from '@/store'
import router from '@/router'
import { routesWhiteList } from '@/config'
import { gp } from './vab'
import request from '../callback/request'

export function setup() {
  router.beforeEach(async (to, from, next) => {
    if (routesWhiteList.includes(to.path)) {
      next()
      return
    }
    if (to.path == '/') {
      next({ path: '/login', replace: true })
      return
    }
    // next({ path: '/login', replace: true })
    // return

    let data = await store.dispatch('user/GetSession')
    let loginstatus = store.getters['user/loginstatus']

    if (loginstatus) {
      let str = localStorage.getItem('accounts') || ''
      str = str.split(',')
      let hasuUser = str.findIndex(function (e) {
        return e == data.uUser
      })
      if (hasuUser == -1) {
        str.push(data.uUser)
        localStorage.setItem('accounts', str.join())
      }

      let UArea = await request('/UArea')
      if (UArea.b_mode == 4) {
        window.location.href = './white/index.html'
      } else {
        if (
          data.utype == '0' ||
          data.utype == '3' ||
          data.utype == '4' ||
          data.utype == '32' ||
          data.utype == '33' ||
          data.utype == '35' ||
          data.utype == '20'
        ) {
          window.location.href = './appnew.html'
        } else if (
          data.utype == '36' ||
          data.utype == '37' ||
          data.utype == '38'
        ) {
          window.location.href = './appweixiu.html'
        } else {
          window.location.href = './app.html'
        }
      }
      next({ path: '/login', replace: true })
      return
    } else {
      gp.$baseMessage('登录失败')
      next({ path: '/login', replace: true })
      return
    }
  })
  // router.beforeEach(async (to, from, next) => {
  //   const { showProgressBar } = store.getters['settings/theme']
  //   if (showProgressBar) VabProgress.start()
  //   // let USearch = await store.dispatch('user/getUSearch')
  //   let hasToken = await store.dispatch('user/GetSession')
  //   if (hasToken && hasToken.uUser) {
  //     if (store.getters['routes/routes'].length) {
  //       // 禁止已登录用户返回登录页
  //       next()
  //     } else {
  //       try {
  //         // 根据路由模式获取路由并根据权限过滤
  //         await store.dispatch('routes/setRoutes', authentication)
  //         next({ ...to, replace: true })
  //       } catch (err) {
  //         console.error('vue-admin-beautiful错误拦截路由:', err)
  //         await store.dispatch('user/resetAll')
  //         next(toLoginRoute(to.path))
  //       }
  //     }
  //   } else {
  //     if (routesWhiteList.includes(to.path)) {
  //       next()
  //     } else {
  //       next(toLoginRoute(to.path))
  //     }
  //   }
  // })
  // router.afterEach((to) => {
  //   document.title = getPageTitle(to.meta.title)
  //   if (VabProgress.status) VabProgress.done()
  // })
}
