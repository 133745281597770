<template>
  <vab-app />
</template>

<script>
  import VabApp from '@/components/VabApp'
  export default {
    name: 'App',
    components: {
      VabApp,
    },
  }
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  // 32
</style>
