import request from '@/z/callback/request'
import { ElMessageBox, ElMessage } from 'element-plus'
import parfn from '@/utils/par'
export async function addEdit(data, showMessage = true) {
  if (data.type == '0') {
    await ElMessageBox.confirm('本设备将免费充电?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
    return await reqchargecaraddfn(data, showMessage)
  } else {
    return await reqchargecaraddfn(data, showMessage)
  }
}
export async function edtEdit(data) {
  if (data.type == '0') {
    await ElMessageBox.confirm('本设备将免费充电?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
    return await reqchargecaredtfn(data)
  } else {
    return await reqchargecaredtfn(data)
  }
}
async function reqchargecaredtfn(data) {
  let par = await request('/ChargeCaredt', data)
  par = parfn(par)
  ElMessage({
    message: par.label,
    type: par.value == 'succeed' ? 'success' : 'error',
  })
  return par
}
async function reqchargecaraddfn(data, showMessage) {
  let par = await request('/ChargeCaradd', data)
  par = parfn(par)
  if (showMessage)
    [
      ElMessage({
        message: par.label,
        type: par.value == 'succeed' ? 'success' : 'error',
      }),
    ]

  return par
}
export function getList(params) {
  let par = request({
    url: '/chargecaradd/getList',
    method: 'get',
    params,
  })

  console.log(parfn(par))
  // return
}

export function doEdit(data) {
  return request({
    url: '/chargecaradd/doEdit',
    method: 'post',
    data,
  })
}

export function doDelete(data) {
  return request({
    url: '/chargecaradd/doDelete',
    method: 'post',
    data,
  })
}
