import html2canvas from 'html2canvas'
import randomString from './randomString'
import { title } from '@/config/index.js'
function zhtml2canvas(el, filename, callback) {
  filename = title + filename
  let promise
  if (typeof callback !== 'function') {
    promise = new Promise((resolve, reject) => {
      callback = function (resolvedata, rejectdata) {
        if (resolvedata) {
          resolve(resolvedata)
        } else {
          reject(rejectdata)
        }
      }
    })
  }
  html2canvas(el).then(function (canvas) {
    let context = canvas.getContext('2d')
    // 【重要】关闭抗锯齿
    context.mozImageSmoothingEnabled = false
    context.webkitImageSmoothingEnabled = false
    context.msImageSmoothingEnabled = false
    context.imageSmoothingEnabled = false

    let type = 'png'
    let imgData = canvas.toDataURL('png')

    let _fixType = function (type) {
      type = type.toLowerCase().replace(/jpg/i, 'jpeg')
      let r = type.match(/png|jpeg|bmp|gif/)[0]
      return 'image/' + r
    }

    // 加工image data，替换mime type
    imgData = imgData.replace(_fixType(type), 'image/octet-stream')

    let saveFile = function (data, filename) {
      let save_link = document.createElementNS(
        'http://www.w3.org/1999/xhtml',
        'a'
      )
      save_link.href = data
      save_link.download = filename

      let event = document.createEvent('MouseEvents')
      event.initMouseEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      )
      save_link.dispatchEvent(event)
      callback(true)
    }
    filename = filename ? filename : randomString(10)

    // download
    saveFile(imgData, filename + '.' + 'png')
  })

  return promise
}
export default zhtml2canvas
