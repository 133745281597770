import { createStore } from 'vuex'
const modules = {}
const files = require.context('./modules', false, /\.js$/)
files.keys().forEach((key) => {
  modules[key.replace(/(modules|\/|\.|js)/g, '')] = {
    ...files(key).default,
    namespaced: true,
  }
})
const store = createStore({ modules })
export function setupStore(app) {
  app.use(store)
}
export default store
