import request from '@/z/callback/request'

import requestbase from '@/z/callback/requestbase'
import store from '../index'
const state = {
  citystation: {},
  stations: new Set(),
  StationPids: {},
  stationfalg: false,
}

const getters = {
  citystation: (state) => state.citystation,
}

const mutations = {
  setCitystation(state, citystation) {
    if (citystation) {
      citystation.forEach((e) => {
        state.citystation[e.id] = e
      })
    } else {
      // state.citystation = {}
    }
  },
  setStations(state, station = []) {
    state.stations = new Set([...state.stations, ...station])
  },
  StationPids(state, { pcs, station = [] }) {
    state.StationPids[pcs] = station
    state.stations = new Set([...state.stations, ...station])
  },
  setstationfalg(state, data) {
    state.stationfalg = true
    state.station = data
  },
  delstation(state) {
    state.station = []
    state.stationfalg = false
    state.stations = new Set()
    state.StationPids = {}
  },
}

const actions = {
  setCitystation({ commit }, citystation) {
    commit('setCitystation', citystation)
  },
  async getstationone({ state, commit }, pcs = store.state.user.token.pcs) {
    // id
    if (pcs == '') {
      return []
    }
    let station = false
    if (state.stationfalg) {
      station = state.station.find((e) => {
        return e.id == pcs
      })
    }
    if (!station) {
      station = [...state.stations].find((e) => {
        return e.id == pcs
      })
    }
    if (!station) {
      let stationreq = await request('/CityStation', { sid: `id in (${pcs})` })
      commit('setStations', stationreq)
      if (stationreq.length == 1) {
        station = stationreq[0]
      }
    }
    return station ? [station] : []
  },

  async getstationpid({ state, commit }, pcs) {
    if (!pcs) {
      return []
    }
    if (state.StationPids[pcs]) {
      return state.StationPids[pcs]
    }
    let Stations = []
    if (state.stationfalg) {
      Stations = state.station.filter((e) => {
        return e.pid == pcs
      })
      return Stations
    }
    Stations = await request('/CityStationPid', { pid: pcs })

    commit('StationPids', { pcs, station: Stations })
    return Stations
  },
  async getstationpc({ dispatch }, pcsid = store.state.user.token.pcs) {
    // state, commit,
    let r = new Set()
    let pcs = await dispatch('getstationone', pcsid)
    if (pcs.length == 0) {
      return []
    }
    let upcs = await dispatch('getstationone', store.state.user.token.pcs)

    let pdatas = await dispatch('getpcspfn', pcsid)

    r = new Set([...upcs, ...pdatas])
    return [...r]
  },
  async getpcspfn({ dispatch }, pcsid, r = []) {
    let dqpcs = await dispatch('getstationone', pcsid)
    if (dqpcs.length == 0) {
      return r
    }
    dqpcs = dqpcs[0]
    let pcsdatas = await dispatch('getstationpid', dqpcs.id)

    r = new Set([...r, ...pcsdatas])

    if (dqpcs.id == store.state.user.token.pcs) {
      return [...r]
    } else {
      return await dispatch('getpcspfn', dqpcs.pid, [...r])
    }
  },
  async getstationpidsfn(
    { dispatch },
    { pcsid = store.state.user.token.pcs, datas = [] }
  ) {
    let dqpcs = await dispatch('getstationone', pcsid)
    console.log(dqpcs, 111111111)
    if (dqpcs.length == 0) {
      return [...datas]
    }
    dqpcs = dqpcs[0]
    datas = new Set([...datas, dqpcs])
    if (dqpcs.pid == store.state.user.token.pcs) {
      return [...datas]
    } else if (dqpcs.id == store.state.user.token.pcs) {
      return [...datas]
    } else {
      return await dispatch('getstationpidsfn', {
        pcsid: dqpcs.pid,
        datas: [...datas],
      })
    }
  },
  async getstationpidsallfn({ dispatch }, array = []) {
    let data = []
    for (let index = 0; index < array.length; index++) {
      const element = array[index]
      data = data.concat(
        await dispatch('getstationpidsfn', { pcsid: element.id })
      )
    }
    data = new Set([...data])
    return [...data]
  },
  async getstationpidsall({ dispatch }, pcss) {
    let parsfn = [],
      r = new Set()
    pcss.forEach((e) => {
      parsfn.push(dispatch('getstationpidsfn', { pcsid: e.id }))
    })
    let datas = await Promise.all(parsfn)
    console.log(datas)
    datas.forEach((e) => {
      r = new Set([...r, ...e])
    })
    let rdata = []
    r.forEach((e) => {
      if (
        !rdata.find((f) => {
          return f.id == e.id
        })
      ) {
        rdata.push(e)
      }
    })
    return [...rdata]
  },
  async getstation({ state, dispatch, commit }, data = []) {
    if (state.stationfalg) {
      return state.station
    }
    let Station = await request('/CityStation', {
      csta: data.length,
      cmax: 1000,
    })
    data = data.concat(Station)
    if (Station.length < 1000) {
      commit('setstationfalg', data)
      return data
    } else {
      return await dispatch('getstation', data)
    }
  },
  async Stationadd({ commit }, data) {
    let par = await requestbase('/CityStationadd', data)
    commit('delstation')
    return par
  },
  async Stationedt({ commit }, data) {
    let par = await request('/CityStationedt', data)
    commit('delstation')
    return par
  },
  async Stationdel({ commit }, id) {
    let par = await request('/CityStationdel', { id: id })
    commit('delstation')
    return par
  },
}

export default { state, getters, mutations, actions }
