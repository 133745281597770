export default function numw(num, fl = 1) {
  let r = Number(num) / fl
  if (isNaN(r)) {
    return '0'
  }
  if (r > 10000) {
    r = r / 10000
    r = `${r}万`
  }
  return r
}
