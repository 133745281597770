import envan from './en'
import zhvan from './zh'
import store from '@/store'
import { createI18n } from 'vue-i18n'
import enLocale from 'element-plus/lib/locale/lang/en'
import zhLocale from 'element-plus/lib/locale/lang/zh-cn'
import lang from './i18nz'
let en = {}
let zh = {}
lang.forEach((e) => {
  en[e.zh] = e.en
  zh[e.zh] = e.zh
})
en.vabI18n = { ...en, ...envan.vabI18n }
zh.vabI18n = { ...zh, ...zhvan.vabI18n }
const messages = {
  en: {
    ...en,
    ...enLocale,
  },
  zh: {
    ...zh,
    ...zhLocale,
  },
}
function getLanguage() {
  return store.getters['settings/language'] || 'zh'
}

const i18n = createI18n({
  locale: getLanguage(),
  messages,
})

export default i18n
