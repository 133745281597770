import deepClone from '@/z/callback/deepClone'
import inputif from '@/z/callback/inputif'
export default function computedtable(datas = []) {
  if (typeof datas != 'object' || !datas) {
    return []
  }
  let r = deepClone(datas).filter((e) => {
    return inputif(e, this.formdata, this.$attrs)
  })
  let rjson = []
  r.forEach(function (m) {
    if (m.tablecallback) {
      rjson = rjson.concat(m.tablecallback())
    } else {
      rjson.push(m)
    }
  })
  return rjson
}
