import $t from '@/z/callback/$t'
export function selectzfn(input = {}, data = {}) {
  let option = input.option
  let r = data[input.value]
  if (typeof option != 'object') {
    return r
  }
  let o = option.find((e) => {
    return e.value == r
  })
  return o ? $t(o.label) : r
}
export function timenewfatefn(str) {
  if (str == '' || !str) {
    return new Date()
  }
  return new Date(Date.parse(str.replace(/-/g, '/')))
}
export function timejlfn(time = '', endtime = '') {
  let birthday = timenewfatefn(time)
  let nowDate = timenewfatefn(endtime)
  let timerToday = nowDate.getTime() //获取现在 距离 标准时间 多少毫秒
  let timerBirthday = birthday.getTime() //得到那天 距离 标准时间 多少毫秒6
  let dayNum = Math.ceil((timerToday - timerBirthday).div(1000))
  if (isNaN(dayNum)) {
    dayNum = 0
  }
  return dayNum
}
export function daystrfn(day) {
  if (!day) {
    return 0
  }
  var d = Math.floor(day / 60 / 24)
  var h = Math.floor((day - d * 60 * 24) / 60)
  var m = parseInt(day) - h * 60 - d * 60 * 24
  if (d != 0) {
    return d + $t('天') + h + $t('小时')
  } else if (h != 0) {
    return h + $t('小时') + m + $t('分')
  } else {
    return m + $t('分')
  }
}
export function timejlMinfn(time, endtime) {
  let t = timejlfn(time, endtime)
  return parseInt(t.div(60))
}

// 其他更多是格式化有如下:
// yyyy:mm:dd|yyyy:mm|yyyy年mm月dd日|yyyy年mm月dd日 hh时MM分等,可自定义组合
export function timeFormat(dateTime = null, fmt = 'yyyy-mm-dd') {
  // 如果为null,则格式化当前时间
  if (!dateTime) dateTime = Number(new Date())
  // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
  if (dateTime.toString().length == 10) dateTime *= 1000
  let date = new Date(dateTime)
  let ret
  let opt = {
    'y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'h+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    's+': date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (let k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
      )
    }
  }
  return fmt
}
export function udjokfn() {}
