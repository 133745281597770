import { selectzfn } from '@/utils/base'
export default async function (input = {}, data = {}) {
  let v = ''
  if (input.callbackasync) {
    v = await input.callbackasync(data)
  } else if (input.callback) {
    v = input.callback(data)
  } else if (input.type == 'select') {
    v = selectzfn(input, data)
  } else {
    v = data[input.value]
  }
  v = v ? v : input.defaultValue ? input.defaultValue : ''
  if (input.dw) {
    v += input.dw
  }
  return v
}
