import request from '@/utils/request'
import timefn from '@/z/callback/timefn'
import { gp } from '@/z/plugins/vab'

let pagingreqs = [
  { url: '/ChargeCarCD', num: 1000, lengzd: 'gtelsum', zd: 'gtel' },
]

let pagingreqi = 0,
  pagingreqj = 0
async function pagingreqfn(url, data, pagingreq) {
  pagingreqi++
  gp.$baseLoading()

  delete data[pagingreq.lengzd]
  let keys = Object.keys(data)
  let reqdatas = []
  for (let i = 0; i < keys.length / pagingreq.num; i++) {
    let min = i * pagingreq.num
    let max = (i + 1) * pagingreq.num
    if (max > keys.length) {
      max = keys.length
    }
    reqdatas.push(keys.slice(min, max))
  }
  let dfreqdatas = []
  reqdatas.forEach(function (e) {
    let p = {}
    p[pagingreq.lengzd] = e.length
    e.forEach((e, i) => {
      p[pagingreq.zd + (i + 1)] = data[e]
    })
    dfreqdatas.push({ url: pagingreq.url, data: p })
  })
  let r = await fbgetdatafn(dfreqdatas)
  pagingreqj++
  if (pagingreqj == pagingreqi) {
    gp.$baseLoading().close()
  }
  return r
}
async function fbgetdatafn(urldata, i = 0, datas = []) {
  if (i >= urldata.length) {
    return datas
  }
  let getdata = await request.post(urldata[i].url, urldata[i].data)
  datas = datas.concat(getdata)
  i++
  if (i >= urldata.length) {
    return datas
  } else {
    return await fbgetdatafn(urldata, i, datas)
  }
}
async function sjpostfn(url, data, datas = [], num = 3) {
  let sje = timefn.gettime(-num, data.sjs, 'yyyy-mm-dd hh:MM:ss')
  let jl = timefn.getUTCtime(sje, data.sje, 1)
  let reqdata = { ...data }
  if (jl > 0) {
    reqdata.sje = sje
  }
  let getdata = await request.post(url, reqdata)
  datas = datas.concat(getdata)
  if (jl > 0) {
    data.sjs = reqdata.sje
    num = getdata.length == 0 ? 30 : 3
    return await sjpostfn(url, data, datas, num)
  } else {
    return datas
  }
}
let zrequest = async (url, data = {}) => {
  if (data.sjs && url != '/GetRfCDArea') {
    let reqdata = await sjpostfn(url, data)
    return reqdata
  }
  let pagingreq = pagingreqs.find(function (e) {
    return e.url == url
  })
  if (pagingreq) {
    let reqdata = await pagingreqfn(url, data, pagingreq)
    return reqdata
  }
  return await request.post(url, data)
}
zrequest.post = zrequest
export default zrequest
