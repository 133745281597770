import request from '@/z/callback/request'

export function getList(data) {
  return request('/USearch', data)
}

export function doEdit(data) {
  return request({
    url: '/usearch/doEdit',
    method: 'post',
    data,
  })
}

export function doDelete(data) {
  return request({
    url: '/usearch/doDelete',
    method: 'post',
    data,
  })
}
