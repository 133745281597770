// import store from '@/store'
export default async function (data) {
  // , datas, bask
  // let b_askfalg = 0
  // if (bask) {
  //   b_askfalg = bask
  // } else if (datas) {
  //   let gtels = datas.map(function (e) {
  //     return e.gtel
  //   })
  //   gtels = [...new Set([...gtels])]

  //   let resdata = { gtelsum: gtels.length }
  //   gtels.forEach(function (e, i) {
  //     resdata[`gtel${i + 1}`] = e
  //   })
  //   let chargecars = await store.dispatch('request/getRequest', {
  //     url: '/ChargeCarCD',
  //     data: resdata,
  //   })
  //   let dqchargecar = chargecars.find(function (f) {
  //     return f.gtel == data.gtel
  //   })
  //   b_askfalg = dqchargecar ? dqchargecar.b_ask : 0
  // } else {
  //   b_askfalg = await store
  //     .dispatch('chargecar/getchargecarbask', data.gtel)
  //     .catch(() => {
  //       return 0
  //     })
  // }
  // / 10000
  return Number(data.dl).div(10000)
  // let num = Number(data.dl) / 10000
  // if (b_askfalg == 1) {
  //   if (num <= num.toFixed(2)) {
  //     return num.toFixed(2)
  //   } else {
  //     return (num + 0.01).toFixed(2)
  //   }
  // } else {
  //   if (num <= num.toFixed(1)) {
  //     return num.toFixed(1)
  //   } else {
  //     return (num + 0.1).toFixed(1)
  //   }
  // }
}
