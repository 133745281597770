import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import '@/z/plugins/element-variables.scss'
export function setup(app) {
  app.use(ElementPlus, {
    size: 'small',
    locale: zhCn,
  })
}
