/**
 * @description 导出网络配置
 **/

module.exports = {
  // 默认的接口地址，开发环境和生产环境都会走/vab-mock-server
  // 正式项目可以选择自己配置成需要的接口地址，如"https://api.xxx.com"
  // 问号后边代表开发环境，冒号后边代表生产环境
  baseURL: process.env.NODE_ENV === 'development' ? '/aaa' : './',
  ewmURL:
    process.env.NODE_ENV === 'development' ? 'http://fdd.gpsserver.cn' : '',
  imgURL: '',
  UploadServlet: 'http://img.gpsserver.cn',
  pars: [
    { label: '操作成功', value: 'succeed' },
    { label: '错误', value: 'err' },
    { label: '输入错误', value: 'err_input' },
    { label: '没有查找到数据', value: 'err_nodata' },
    { label: '数据重复', value: 'err_repeat' },
    { label: '电话号码重复', value: 'err_repeattel' },
    { label: '数据库操作错误', value: 'err_sql' },
    { label: '设备未注册过', value: 'err_noreg' },
    { label: '该桩号未登记，请联系管理员！！', value: 'err_gnoreg' },
  ],

  // http://img.gpsserver.cn
  // 配后端数据的接收方式application/json;charset=UTF-8 或 application/x-www-form-urlencoded;charset=UTF-8
  contentType: 'application/x-www-form-urlencoded;charset=UTF-8',
  // 最长请求时间
  requestTimeout: 300000,
  // 操作正常code，支持String、Array、int多种类型
  successCode: [200, 0, '200', '0'],
  // 数据状态的字段名称
  statusName: 'code',
  // 状态信息的字段名称
  messageName: 'msg',
}
