import request from '@/z/callback/request'
const state = {
  gtel: '',
  gtels: [],
}

const getters = {
  gtel: (state) => state.gtel,
}

const mutations = {
  setGtel(state, gtel) {
    if (gtel) {
      state.gtel = gtel
    } else {
      state.gtel = []
    }
  },
  setgtels(state, gtels) {
    state.gtels = [...new Set([...state.gtels, ...gtels])]
  },
}

const actions = {
  setGtel({ commit }, gtel) {
    commit('setGtel', gtel)
  },
  async getgtel({ state, commit }, gtel) {
    let r = state.gtels.find((e) => {
      return e.gtel == gtel
    })
    if (r) {
      return r
    }
    let data = await request('/gtel', { gtel: gtel })
    r = data.length == 1 ? data[0] : { gtel }
    commit('setgtels', data)
    return r
  },
}

export default { state, getters, mutations, actions }
